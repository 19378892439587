<template>
  <div class="error-500-page">
    <div class="error-500-page-text">
      There is a disruption in service, it is usually caused by slower internet
      or browser.
      <br>Also try to enable or switch/disable a VPN.
    </div>
    <div
      v-if="errorMessage"
      class="error-500-page-details"
    >
      {{ errorMessage }}
    </div>
    <a
      class="error-500-page-button"
      href="#"
      @click.prevent="$router.back()"
    >Go Back</a>
  </div>
</template>

<script>
export default {
  props: {
    error: Object,
    errorMessage: String
  },

}
</script>